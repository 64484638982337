<template src="./ForgotPassword.html"></template>
<style scoped lang="scss" src="./ForgotPassword.scss"></style>

<script>
import ForgotPasswordForm from "../../../../components/form/forgot-password/ForgotPasswordForm";
import {ServiceFactory} from "@/services";

const AuthService = ServiceFactory.get('auth')

export default {
  name: 'ForgotPassword',
  components: {ForgotPasswordForm},
  data() {
    return {
      isLoading: false,
      error: ''
    }
  },
  methods: {
    async forgotPassword(dataForm) {
      this.isLoading = true
      const data = {
        email: dataForm.email,
      }
      const response = await AuthService.forgotPassword(data).catch((error) => {
        this.error = error || ''
        return ''
      })
      this.isLoading = false
      if (response)  await this.$router.push({name: 'routes.auth.forgot-password-success'})
    }
  }
}
</script>